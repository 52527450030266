import { MenuItems } from "../common/constants";
const handleUnAuthorisedRoute = (userRole, route) => {
  return route;
};

export const CENTRAL_ADMIN = [
  {
    name: MenuItems.stats,
    // svg: <Venue />,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/"),
    subMenu: [],
  },
  {
    name: MenuItems.leagues,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/leagues"),
    disabled: false,
    subMenu: [],
  },
  {
    name: MenuItems.matches,
    // svg: <Home />,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/matches"),
    disabled: false,
    subMenu: [],
  },
  {
    name: MenuItems.teams,
    // svg: <Home />,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/teams"),
    disabled: false,
    subMenu: [],
  },
  {
    name: MenuItems.players,
    // svg: <Venue />,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/players"),
    subMenu: [],
  },
  {
    name: MenuItems.futyPoints,
    // svg: <Venue />,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/futy-points"),
    subMenu: [],
  },
  {
    name: MenuItems.rewards,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/rewards"),
    subMenu: [],
  },
  {
    name: MenuItems.referrals,
    // svg: <Venue />,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/referrals"),
    subMenu: [],
  },
  {
    name: MenuItems.pushNotification,
    // svg: <Venue />,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/push-notification"),
    subMenu: [],
  },
  {
    name: MenuItems.userManagement,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/manage-user"),
    subMenu: [],
  },
  {
    name: MenuItems.userIdentity,
    disabled: false,
    route: handleUnAuthorisedRoute("CENTRAL_ADMIN", "/user-identity"),
    subMenu: [],
  },
];
